/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import { RulePopup } from './components';

import Abbott from 'assets/images/Abbott.png';
import Banner from 'assets/images/banner.png';
import Button, { TextButton } from 'components/Button';
import Image from 'components/Image';
import WrapModal from 'components/Modal';
import Text from 'components/Text';
import { viewsService } from 'services/request/stat';

const HomeContainer: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [isShowModal, setIsShowModal] = useState(false);
  const [permission, setPermission] = useState<PermissionState>('prompt');

  useEffect(() => {
    (async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        setPermission('granted');
      } catch (error) {
        setPermission('denied');
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await viewsService();
    })();
  }, []);

  const handleClick = () => {
    history.push({
      pathname: '/ar',
      search: window.location.search,
    });
  };

  const handlePreview = () => {
    history.push({
      pathname: '/preview',
      search: window.location.search,
    });
  };

  return (
    <div className="p-home">
      <div className="container">
        <div className="banner">
          <Image imgSrc={Banner} alt="banner" ratio="banner" />
        </div>
        <div className="sub-banner">
          <Text type="p" modifiers={['silver-sand', 'c1', 'center']}>* Trong số các sản phẩm Ensure</Text>
        </div>

        <div className="controls">
          <Button
            type="button"
            onClick={handleClick}
            disabled={permission !== 'granted'}
            loading={loading}
          >
            <div>
              <Text type="p" modifiers={['white', 'p2']}>
                KHÁM PHÁ NGAY
              </Text>
              <Text type="p" modifiers={['white', 'c1']}>
                Bí quyết chăm sóc sức khoẻ
              </Text>
              <Text type="p" modifiers={['white', 'c1']}>
                từ dưỡng chất thiên nhiên
              </Text>
            </div>
          </Button>
        </div>

        <div className="controls preview">
          <Button
            type="button"
            onClick={handlePreview}
          >
            <div>
              <Text type="p" modifiers={['white', 'p2', 'uppercase']}>
                Nhận gói dùng thử
              </Text>
              <Text type="p" modifiers={['white', 'p2', 'uppercase']}>
                MIỄN PHÍ
              </Text>
            </div>
          </Button>
        </div>

        <div className="rule">
          <TextButton
            modifiers={['white', 'underline']}
            onClick={() => setIsShowModal(true)}
          >
            Tham gia để nhận
            <br />
            quà tặng đặc biệt từ Ensure Gold
          </TextButton>
        </div>

        <div className="bottom adjust-flex">
          <Text type="p" modifiers={['taupeGray']}>
            Hotline hỗ trợ kỹ thuật: 0918.454.049
            <br />
            ENS - C 325 - 21
          </Text>
          <div className="abbott">
            <Image ratio="abbott" imgSrc={Abbott} alt="Abbott" />
          </div>
        </div>
      </div>
      <WrapModal
        isOpen={isShowModal}
        modifiers={['bg-antiflashWhite', 'icon-gold']}
        handleClose={() => setIsShowModal(false)}
      >
        <RulePopup />
      </WrapModal>
    </div>
  );
};

export default HomeContainer;
