/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import { TermsPopup, OtpPopup, ReceivedPopup } from './components';
import { validationSchemaFormSubmit } from './functions';

import Abbott from 'assets/images/Abbott.png';
import Banner from 'assets/images/banner_2.png';
import Button, { TextButton } from 'components/Button';
import Checkbox from 'components/Checkbox';
import Image from 'components/Image';
import Input from 'components/Input';
import WrapModal from 'components/Modal';
import Pulldown, { OptionType } from 'components/Pulldown';
import Text from 'components/Text';
import useCallService from 'hooks/useCallService';
import { getCitiesListService } from 'services/request/cities';
import { requestResendOTPService, requestSaveConsumerService, requestSendOTPService } from 'services/request/otp';

const CHECKED = '1';
const UNCHECKED = '0';

type FormSubmit = {
  name: string;
  email: string;
  phone: string;
  cities?: OptionType;
  districts?: OptionType;
  confirm?: string;
};

const initFormSubmit: FormSubmit = {
  name: '',
  email: '',
  phone: '',
  confirm: '0',
};

const RequestOTPContainer: React.FC = () => {
  const history = useHistory();
  const url = new URL(window.location.href);
  const [isOpenTerms, setIsOpenTerms] = useState(false);
  const [isOpenOTP, setIsOpenOTP] = useState(false);
  const [isOpenReceived, setIsOpenReceived] = useState(false);
  const [isVerifyOtpSuccess, setIsVerifyOtpSuccess] = useState(false);
  const [loadingRequestOTP, setLoadingRequestOTP] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [timestamp, setTimestamp] = useState('');

  const method = useForm<FormSubmit>({
    defaultValues: initFormSubmit,
    resolver: yupResolver(validationSchemaFormSubmit(isVerifyOtpSuccess)),
  });

  const { phone, cities, districts } = method.watch();

  const [citiesList, setCitiesList] = useState<OptionType[]>([]);

  const { data } = useCallService(() => getCitiesListService(), []);

  useEffect(() => {
    if (data) {
      const citiesEmp: OptionType[] = [];
      Object.entries(data).forEach((item) => {
        if (item[0] && item[1]?.name) {
          citiesEmp.push({
            label: item[1].name,
            value: item[0],
          });
        }
      });
      setCitiesList(citiesEmp);
    }
  }, [data]);

  const districtsList = useMemo(() => {
    const result: OptionType[] = [];
    if (cities && data) {
      const ele = data[cities.value];
      if (ele.districts) {
        Object.entries(ele.districts).forEach((i) => {
          if (i[0] && i[1]) {
            result.push({
              label: i[1],
              value: i[0],
            });
          }
        });
      }
    }
    return result;
  }, [cities, data]);

  const submitForm = useCallback(async (values: FormSubmit) => {
    try {
      setLoadingSubmit(true);
      const channelParams = url.searchParams.get('channel');
      const utm_sourceParams = url.searchParams.get('utm_source');
      let utm_source: string | null = channelParams;

      if ((!utm_source && utm_sourceParams) || (utm_source && utm_sourceParams)) {
        utm_source = utm_sourceParams;
      }

      await requestSaveConsumerService({
        name: values.name,
        email: values.email,
        phone: values.phone,
        city_code: String(values.cities?.value) || '',
        district_code: String(values.districts?.value) || '',
        utm_source,
        utm_campaign: url.searchParams.get('utm_campaign'),
        utm_content: url.searchParams.get('utm_content'),
        utm_medium: url.searchParams.get('utm_medium'),
        utm_terms: url.searchParams.get('utm_terms'),
        utm_string: window.location.href,
        affiliate: url.searchParams.get('affiliate'),
      });
      history.push({
        pathname: '/complete',
        search: window.location.search,
      });
    } catch (error) {
      if (error?.length && error[0]?.message === '4227') {
        method.setError('email', {
          type: 'validate',
          message: 'Email đã được đăng ký, vui lòng thử lại',
        });
      }
    } finally {
      setLoadingSubmit(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url.searchParams]);

  const handleResendOTP = useCallback(async () => {
    try {
      setLoadingRequestOTP(true);
      await requestResendOTPService({ phone });
      setIsOpenOTP(true);
    } catch (error) {
      if (error?.length && error[0]?.message === '4224') {
        method.setError('phone', {
          type: 'validate',
          message: 'Xin đợi 30 giây để nhận OTP mới',
        });
      }
    } finally {
      setLoadingRequestOTP(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone]);

  const handleRequestOTP = useCallback(async () => {
    try {
      const isSend = await method.trigger('phone');
      if (isSend) {
        setLoadingRequestOTP(true);
        await requestSendOTPService({ phone });
        setIsOpenOTP(true);
      }
    } catch (error) {
      if (error?.length && error[0]?.message === '4222') {
        handleResendOTP();
      }
      if (error?.length && error[0]?.message === '4221') {
        setIsVerifyOtpSuccess(true);
      }
      if (error?.length && error[0]?.message.includes('4220')) {
        setIsOpenReceived(true);
        setTimestamp(error[0].message.slice(5));
      }
    } finally {
      setLoadingRequestOTP(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone]);

  return (
    <div className="p-reOtp">
      <div className="container">

        <div className="thumbnail">
          <Image ratio="banner-2" imgSrc={Banner} alt="banner" />
        </div>

        <div className="description">
          <Text type="div" modifiers={['center']}>
            <Text type="span" modifiers={['white', 'p2', 'uppercase']}>
              Hãy trở thành người trải nghiệm đầu tiên
            </Text>
            <br />
            <Text type="span" modifiers={['white', 'p2']}>
              Tặng 1 gói dùng thử 60.6g MIỄN PHÍ
            </Text>
          </Text>
        </div>
        <div className="form">
          <FormProvider {...method}>
            <form onSubmit={method.handleSubmit(submitForm)} noValidate>
              <div className="item">
                <div className="label">
                  <Text type="span" modifiers={['white', 'p2']}>
                    Họ tên
                  </Text>
                </div>
                <Controller
                  name="name"
                  control={method.control}
                  render={({ field }) => (
                    <div className="value">
                      <Input
                        {...field}
                        type="text"
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                      <ErrorMessage
                        name="name"
                        errors={method.formState.errors}
                        render={({ message }) => (
                          <Text type="span" modifiers={['cardinal', 'p2']}>
                            {message}
                          </Text>
                        )}
                      />
                    </div>
                  )}
                />
              </div>

              <div className="item">
                <div className="label">
                  <Text type="span" modifiers={['white', 'p2']}>
                    Địa chỉ email
                  </Text>
                </div>
                <Controller
                  name="email"
                  control={method.control}
                  render={({ field }) => (
                    <div className="value">
                      <Input
                        {...field}
                        type="text"
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                      <ErrorMessage
                        name="email"
                        errors={method.formState.errors}
                        render={({ message }) => (
                          <Text type="span" modifiers={['cardinal', 'p2']}>
                            {message}
                          </Text>
                        )}
                      />
                    </div>
                  )}
                />
              </div>

              <div className="item">
                <div className="label">
                  <Text type="span" modifiers={['white', 'p2']}>
                    Số điện thoại
                  </Text>
                </div>
                <Controller
                  name="phone"
                  control={method.control}
                  render={({ field }) => (
                    <div
                      className={`value ${
                        isVerifyOtpSuccess ? 'disabled' : ''
                      }`}
                    >
                      <Input
                        {...field}
                        type="text"
                        onChange={(e) => {
                          if (method.formState.errors.phone) {
                            method.clearErrors('phone');
                          }
                          field.onChange(e.target.value);
                        }}
                      />
                      <ErrorMessage
                        name="phone"
                        errors={method.formState.errors}
                        render={({ message }) => (
                          <Text type="span" modifiers={['cardinal', 'p2']}>
                            {message}
                          </Text>
                        )}
                      />
                    </div>
                  )}
                />
              </div>

              <div className="item">
                <div className="label">
                  <Text type="span" modifiers={['white', 'p2']}>
                    Tỉnh/Thành phố
                  </Text>
                </div>
                <Controller
                  name="cities"
                  control={method.control}
                  render={({ field }) => (
                    <div className="value">
                      <Pulldown
                        {...field}
                        modifiers="otp"
                        options={citiesList}
                        placeholder="Chọn tỉnh thành"
                        onChange={(e: OptionType) => {
                          field.onChange(e);
                          if (districts) {
                            method.setValue('districts', {
                              label: 'Chọn quận huyện',
                              value: '',
                            });
                          }
                        }}
                      />
                      <ErrorMessage
                        name="cities"
                        errors={method.formState.errors}
                        render={() => (
                          <Text type="span" modifiers={['cardinal', 'p2']}>
                            Chọn thành phố
                          </Text>
                        )}
                      />
                    </div>
                  )}
                />
              </div>

              <div className="item">
                <div className="label">
                  <Text type="span" modifiers={['white', 'p2']}>
                    Quận/Huyện
                  </Text>
                </div>
                <Controller
                  name="districts"
                  control={method.control}
                  render={({ field }) => (
                    <div className="value">
                      <Pulldown
                        {...field}
                        modifiers="otp"
                        options={districtsList}
                        value={method.watch('districts')}
                        placeholder="Chọn quận huyện"
                      />
                      <ErrorMessage
                        name="districts"
                        errors={method.formState.errors}
                        render={() => (
                          <Text type="span" modifiers={['cardinal', 'p2']}>
                            Chọn quận huyện
                          </Text>
                        )}
                      />
                    </div>
                  )}
                />
              </div>

              <div className="item">
                <Controller
                  name="confirm"
                  control={method.control}
                  render={({ field }) => (
                    <div className="checked">
                      <Checkbox
                        {...field}
                        checked={field.value === CHECKED}
                        id="asd"
                        onChange={() => {
                          if (field.value === CHECKED) {
                            field.onChange(UNCHECKED);
                          } else {
                            field.onChange(CHECKED);
                          }
                        }}
                      >
                        <TextButton
                          onClick={() => {
                            setIsOpenTerms(true);
                            field.onChange(CHECKED);
                          }}
                          type="button"
                        >
                          <Text
                            type="span"
                            modifiers={[
                              method.formState.errors?.confirm
                              || (method.watch('confirm') !== CHECKED
                                && !method.formState.isSubmitSuccessful && isVerifyOtpSuccess)
                                ? 'cardinal'
                                : 'white',
                              'underline',
                              'p2',
                              'italic',
                            ]}
                          >
                            Tôi đã đọc và đồng ý với điều khoản của chương trình
                          </Text>
                        </TextButton>
                      </Checkbox>
                    </div>
                  )}
                />
              </div>
              <div
                className={`submit ${
                  isVerifyOtpSuccess ? 'submit_verify' : ''
                }`}
              >
                <Button type="button" disabled={!phone} onClick={handleRequestOTP} loading={loadingRequestOTP}>
                  <Text type="span" modifiers={['white', 'p1', 'uppercase']}>
                    NHẬN MÃ OTP
                  </Text>
                </Button>
                <Button type="submit" loading={loadingSubmit}>
                  <Text type="span" modifiers={['white', 'p1', 'uppercase']}>
                    GỬI THÔNG TIN
                  </Text>
                </Button>
              </div>
            </form>
          </FormProvider>

          <div className="bottom adjust-flex">
            <Text type="p" modifiers={['taupeGray']}>
              Hotline hỗ trợ kỹ thuật: 0918.454.049
              <br />
              ENS - C 325 - 21
            </Text>
            <div className="abbott">
              <Image ratio="abbott" imgSrc={Abbott} alt="Abbott" />
            </div>
          </div>
        </div>
      </div>
      <WrapModal
        isOpen={isOpenTerms}
        handleClose={() => setIsOpenTerms(false)}
        modifiers={['bg-white']}
      >
        <TermsPopup handleClose={() => setIsOpenTerms(false)} />
      </WrapModal>
      <WrapModal
        isOpen={isOpenOTP}
        handleClose={() => setIsOpenOTP(false)}
        modifiers={['bg-white']}
      >
        <OtpPopup
          phone={phone}
          handleClose={() => setIsOpenOTP(false)}
          handleTriggerSuccess={() => setIsVerifyOtpSuccess(true)}
        />
      </WrapModal>
      <WrapModal
        isOpen={isOpenReceived}
        handleClose={() => setIsOpenReceived(false)}
        modifiers={['bg-white']}
      >
        <ReceivedPopup
          time={timestamp}
          handleClose={() => setIsOpenReceived(false)}
        />
      </WrapModal>
    </div>
  );
};

export default RequestOTPContainer;
