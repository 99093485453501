import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Logo from 'assets/logo/logo.svg';
import Icon from 'components/Icon';
import Image from 'components/Image';
import Text from 'components/Text';
import DetectMaker from 'libs/MarkerDetector';

const CameraContainer: React.FC = () => {
  const history = useHistory();
  const cameraRef = useRef<HTMLVideoElement>(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let terminateWorker: () => void;
    let stopCamera: () => void;

    (async () => {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: 'environment',
          width: 600,
          height: 800,
        },
      });

      stopCamera = () => {
        mediaStream.getTracks().forEach((t) => t.stop());
      };

      if (!cameraRef.current) {
        return;
      }

      const cameraEl = cameraRef.current;

      cameraEl.srcObject = mediaStream;

      cameraEl.addEventListener('loadedmetadata', async () => {
        const [loadingPromise, foundPromise, _terminateWorker] = DetectMaker(
          cameraEl,
        );
        terminateWorker = _terminateWorker;

        await loadingPromise;
        setLoading(false);

        await foundPromise;
        history.push({
          pathname: '/preview',
          search: window.location.search,
        });
      });
    })();

    return () => {
      if (terminateWorker) {
        terminateWorker();
      }
      if (stopCamera) {
        stopCamera();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-scan">
      <div className="container">
        <div className="logo">
          <Image ratio="logo" imgSrc={Logo} alt="logo" />
        </div>
        <div className="description">
          <Text type="div" modifiers={['center']}>
            <Text type="span" modifiers={['white', 't2']}>
              Hãy hướng camera vào
            </Text>
            <br />
            <Text type="span" modifiers={['mediumSeaGreen', 't2']}>
              BIỂU TƯỢNG ĐẠM THỰC VẬT
            </Text>
            <br />
            <Text type="span" modifiers={['white', 't2']}>
              để cùng Ensure Gold
            </Text>
            <br />
            <Text type="span" modifiers={['mediumSeaGreen', 't2']}>
              KHÁM PHÁ CÔNG NGHỆ AR
            </Text>
            <br />
            <Text type="span" modifiers={['mediumSeaGreen', 't2']}>
              VƯỢT TRỘI
            </Text>
          </Text>
        </div>

        <div className="box">
          <div className="box_camera">
            <video
              ref={cameraRef}
              className="camera"
              controls={false}
              disableRemotePlayback
              playsInline
              autoPlay
              muted
            />
            {loading && (
              <div className="box_loading">
                <Icon iconName="loading" />
              </div>
            )}
          </div>
        </div>

        <div className="bottom adjust-flex">
          <Text type="p" modifiers={['taupeGray']}>
            Hotline hỗ trợ kỹ thuật: 0918.454.049
            <br />
            ENS - C 325 - 21
          </Text>
        </div>
      </div>
    </div>

  );
};

export default CameraContainer;
