/* eslint-disable no-unused-vars */
import React, {
  useRef, useState, useEffect, useMemo,
} from 'react';

import { formatDate, formatTime } from './functions';

import Button, { TextButton } from 'components/Button';
import Icon from 'components/Icon';
import Text from 'components/Text';
import {
  requestResendOTPService,
  requestVerifyOTPService,
} from 'services/request/otp';

interface TermsPopupProps {
  handleClose?: () => void;
}

export const TermsPopup: React.FC<TermsPopupProps> = ({ handleClose }) => (
  <div className="terms_popup">
    <div className="terms_popup_content">
      <ul className="terms_popup_list">
        <li>
          <Text type="span" modifiers={['t2', 'mediumSeaGreen', '700']}>
            Tôi đồng ý cho công ty Abbott liên hệ tôi qua điện thoại/ tin nhắn
            về các nội dung thông tin sự kiện, sản phẩm thông tin chương trình
            khuyến mại và chăm sóc khách hàng.
          </Text>
        </li>
        <li>
          <Text type="span" modifiers={['t2', 'mediumSeaGreen', '700']}>
            Tôi đồng ý rằng thông tin tôi cung cấp bao gồm hình ảnh sẽ được chia
            sẻ cho các công ty trong tập đoàn Abbott và các đơn vị ủy quyền nhằm
            mục đích trên.
          </Text>
        </li>
        <li>
          <Text type="span" modifiers={['t2', 'mediumSeaGreen', '700']}>
            Để ngưng tham gia chương trình vui lòng gọi 19001519
          </Text>
        </li>
      </ul>
      <div className="terms_popup_accept">
        <Button type="button" onClick={handleClose}>
          <div>
            <Text type="p" modifiers={['white', 'p2']}>
              TÔI ĐỒNG Ý
            </Text>
            <Text type="p" modifiers={['white', 'p2']}>
              VỚI ĐIỀU KHOẢN NÀY
            </Text>
          </div>
        </Button>
      </div>
    </div>
  </div>
);

interface OtpPopupProps {
  phone?: string;
  handleClose?: () => void;
  handleTriggerSuccess?: () => void;
}

export const OtpPopup: React.FC<OtpPopupProps> = ({ phone, handleClose, handleTriggerSuccess }) => {
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [timeResend, setTimeResend] = useState(30);
  const [timeOtp, setTimeOtp] = useState(300);
  const [loadingResend, setLoadingResend] = useState(false);
  const [errorVerify, setErrorVerify] = useState('');
  const [loadingVerify, setLoadingVerify] = useState(false);

  const minutes = timeOtp / 60 < 10
    ? `0${Math.floor(timeOtp / 60)}`
    : Math.floor(timeOtp / 60);
  const seconds = timeOtp % 60 < 10 ? `0${timeOtp % 60}` : timeOtp % 60;

  const onSubmit = async () => {
    try {
      setErrorVerify('');
      if (otp.join('').length === 6 && phone) {
        setLoadingVerify(true);
        await requestVerifyOTPService({
          phone,
          otp: otp.join(''),
        });
        if (handleClose) {
          handleClose();
        }
        if (handleTriggerSuccess) {
          handleTriggerSuccess();
        }
      }
    } catch (error) {
      if (error?.length && error[0]?.message) {
        const code = error[0].message;
        if (code === '4225') {
          setErrorVerify('Mã OTP không hợp lệ. Xin vui lòng nhập lại');
        }
        if (code === '4226') {
          setErrorVerify('Mã OTP đã hết hiệu lực. Xin vui lòng nhận mã khác');
        }
      }
    } finally {
      setLoadingVerify(false);
    }
  };

  const handleRequestResendOTP = async () => {
    try {
      if (phone) {
        setLoadingResend(true);
        await requestResendOTPService({ phone });
        setTimeResend(30);
        setTimeOtp(300);
        setErrorVerify('');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setLoadingResend(false);
    }
  };

  useEffect(() => {
    const count = setTimeout(() => {
      if (timeResend > 0) {
        setTimeResend(timeResend - 1);
      } else {
        clearTimeout(count);
      }
    }, 1000);
    return () => {
      clearTimeout(count);
    };
  }, [timeResend]);

  useEffect(() => {
    const count = setTimeout(() => {
      if (timeOtp > 0) {
        setTimeOtp(timeOtp - 1);
      } else {
        clearTimeout(count);
      }
    }, 1000);
    return () => {
      clearTimeout(count);
    };
  }, [timeOtp]);

  const otpRef = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];
  useEffect(() => {
    if (otp[0].length > 1) {
      setOtp(otp[0].split(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  return (
    <div className="otp-popup">
      {loadingResend && (
        <div className="otp-popup_loading">
          <Icon iconName="loadingBlack" />
        </div>
      )}
      <div className="main">
        <div className="title">
          <Text
            type="p"
            modifiers={['black', 't2', 'center', 'uppercase']}
          >
            xác thực otp
          </Text>
        </div>
        <div className="content">
          <Text type="p" modifiers={['mediumSeaGreen', 'p1', 'center']}>
            Mã OTP đã được gửi tới số điện thoại
            <Text type="span" modifiers={['black', 'p1', 'center']}>
              {' '}
              {phone}
            </Text>
            . Xin vui lòng nhập mã OTP để xác thực tài khoản đã đăng ký
          </Text>
        </div>
        <div className="input">
          {otp.map((_, idx) => (
            <input
              key={`item${idx.toString()}`}
              name={`otpInput${idx + 1}`}
              type="number"
              maxLength={1}
              ref={otpRef[idx]}
              value={otp[idx]}
              onChange={(value) => {
                const slice = otp.slice();
                slice[idx] = value.currentTarget.value;
                setOtp(slice);
                if (idx < otp.length - 1) {
                  otpRef[idx + 1].current?.focus();
                }
                if (errorVerify) {
                  setErrorVerify('');
                }
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 8) {
                  const slice = otp.slice();
                  if (idx === 5) {
                    slice[idx] = '';
                    slice[idx - 1] = '';
                  } else {
                    slice[idx - 1] = '';
                  }
                  setOtp(slice);
                  if (idx > 0) {
                    otpRef[idx - 1].current?.focus();
                  }
                }
                if (errorVerify) {
                  setErrorVerify('');
                }
              }}
            />
          ))}
        </div>
        {errorVerify && (
          <div className="error">
            <Text type="p" modifiers={['center']}>
              {errorVerify}
            </Text>
          </div>
        )}
        {!timeOtp ? (
          <Text type="p" modifiers={['jazzberry-jam', 'p3', 'center']}>
            OTP đã hết hiệu lực. Yêu cầu gửi lại OTP
          </Text>
        ) : (
          <Text type="p" modifiers={['jazzberry-jam', 'p3', 'center']}>
            OTP sẽ hết hiệu lực sau
            {' '}
            {minutes}
            :
            {seconds}
          </Text>
        )}
        <div className="resend-otp">
          <Text type="span" modifiers={['mediumSeaGreen']}>Chưa nhận được mã ?</Text>
          <TextButton
            type="button"
            modifiers={['p1', 'black', '700', 'underline']}
            onClick={handleRequestResendOTP}
            disabled={!!timeResend}
          >
            Gửi lại mã
            {' '}
            {timeResend ? `(${timeResend}s)` : ''}
          </TextButton>
        </div>
        <div className="submit">
          <Button
            type="button"
            onClick={onSubmit}
            disabled={otp.filter((item) => item).length !== 6}
            loading={loadingVerify}
          >
            <Text type="span" modifiers={['white', 'p1', 'uppercase']}>
              Xác thực
            </Text>
          </Button>
        </div>
      </div>
    </div>
  );
};

interface ReceivedPopupProp {
  handleClose?: () => void;
  time: string;
}

export const ReceivedPopup: React.FC<ReceivedPopupProp> = ({ time, handleClose }) => {
  const handleClick = () => {
    window.location.href = 'https://ensure.com.vn/chuong-trinh-khuyen-mai/khuyen-mai-thang-7-thang-9';
  };

  const createAt = useMemo(() => {
    if (!time) return undefined;

    const date = new Date(Number(time) * 1000);
    return date;
  }, [time]);

  return (
    <div className="received_popup">
      <div className="title">
        <Text
          type="p"
          modifiers={['black', 't2', 'center', 'uppercase']}
        >
          Thông báo
        </Text>
      </div>
      <div className="description">
        {createAt && (
          <>
            <Text type="p" modifiers={['p1', 'yale-blue', '700', 'center']}>
              Số điện thoại này đã được đăng ký nhận mẫu thử Ensure Gold vào lúc
              {' '}
              {formatTime(createAt)}
              {' '}
              ngày
              {' '}
              {formatDate(createAt)}
              .
            </Text>
            <Text type="p" modifiers={['p1', 'yale-blue', '700', 'center']}>
              Xin quý khách vui lòng vào trang
              [
              <a className="link" href="https://ensure.com.vn/chuong-trinh-khuyen-mai/khuyen-mai-thang-7-thang-9">
                https://ensure.com.vn/chuong-trinh-khuyen-mai/khuyen-mai-thang-7-thang-9
              </a>
              ]
              để xem thêm các chương trình khuyến mãi hấp dẫn khác của Ensure Gold
            </Text>
          </>
        )}
      </div>
      <div className="button">
        <Button type="button" onClick={handleClick}>
          <Text type="p" modifiers={['white', 'p1', 'uppercase']}>
            Đã Hiểu
          </Text>
        </Button>
      </div>
    </div>
  );
};
