import React from 'react';

import Abbott from 'assets/images/Abbott.png';
import Banner from 'assets/images/banner.png';
import Image from 'components/Image';
import Text from 'components/Text';

const Complete: React.FC = () => (
  <div className="p-complete">
    <div className="container">
      <div className="banner">
        <Image imgSrc={Banner} alt="banner" ratio="banner" />
      </div>

      <div className="sub-banner">
        <Text type="p" modifiers={['silver-sand', 'c1', 'center']}>* Trong số các sản phẩm Ensure</Text>
      </div>

      <div className="title">
        <Text type="p" modifiers={['mediumSeaGreen', '700', 't1', 'center']}>
          {' '}
          HOÀN THÀNH
          {' '}
        </Text>
      </div>
      <div className="subtext">
        <Text type="p" modifiers={['t3', '700', 'white', 'center']}>
          CẢM ƠN BẠN ĐÃ THAM GIA
          <br />
          TRẢI NGHIỆM CÔNG NGHỆ AR
          <br />
          CÙNG ENSURE GOLD
        </Text>
      </div>
      <div className="bottom adjust-flex">
        <Text type="p" modifiers={['taupeGray']}>
          Hotline hỗ trợ kỹ thuật: 0918.454.049
          <br />
          ENS - C 325 - 21
        </Text>
        <div className="abbott">
          <Image ratio="abbott" imgSrc={Abbott} alt="Abbott" />
        </div>
      </div>
    </div>
  </div>
);

export default Complete;
