import React from 'react';
import { useHistory } from 'react-router';

import Abbott from 'assets/images/Abbott.png';
import Banner from 'assets/images/banner.png';
import Button from 'components/Button';
import Image from 'components/Image';
import Text from 'components/Text';

const StartNow: React.FC = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push({
      pathname: '/request-otp',
      search: window.location.search,
    });
  };

  return (
    <div className="p-start">
      <div className="container">
        <div className="banner">
          <Image imgSrc={Banner} alt="banner" ratio="banner" />
        </div>

        <div className="sub-banner">
          <Text type="p" modifiers={['silver-sand', 'c1', 'center']}>* Trong số các sản phẩm Ensure</Text>
        </div>

        <div className="controls">
          <Button
            type="button"
            onClick={handleClick}
          >
            <div>
              <Text type="p" modifiers={['white', 'p2', 'uppercase']}>
                Nhận gói dùng thử
              </Text>
              <Text type="p" modifiers={['white', 'p2', 'uppercase']}>
                MIỄN PHÍ
              </Text>
            </div>
          </Button>
        </div>

        <div className="title">
          <Text type="p" modifiers={['mediumSeaGreen', '700', 't1', 'center']}>
            CHÚC MỪNG
          </Text>
        </div>
        <div className="subtext">
          <Text type="p" modifiers={['p3', '700', 'white', 'center']}>
            BẠN MAY MẮN LÀ MỘT TRONG NHỮNG NGƯỜI
            <br />
            ĐẦU TIÊN NHẬN GÓI DÙNG THỬ
            {' '}
            <Text type="span" modifiers={['gold']}>MIỄN PHÍ</Text>
            <br />
            ENSURE GOLD ĐẠM THỰC VẬT CHẤT LƯỢNG CAO
            <br />
            <br />
            ĐỂ LẠI THÔNG TIN ĐỂ ĐƯỢC GIAO HÀNG TẬN NHÀ
            <br />
            <Text type="span" modifiers={['gold']}>MIỄN PHÍ</Text>
            .
            <br />
            SỐ LƯỢNG CÓ HẠN.
          </Text>
        </div>
        <div className="bottom adjust-flex">
          <Text type="p" modifiers={['taupeGray']}>
            Hotline hỗ trợ kỹ thuật: 0918.454.049
            <br />
            ENS - C 325 - 21
          </Text>
          <div className="abbott">
            <Image ratio="abbott" imgSrc={Abbott} alt="Abbott" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartNow;
